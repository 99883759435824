:root {
  --vibrant-purple: rgb(120 23 255);
  --light-purple: rgb(167 138 255);
  --light-background: rgb(249 248 255);
  --dark-background: #090d2a;
  --card-dark: #181c3c;
  --card-dark-hover: #222750;
  --pink: rgb(214 133 255);
  --light-pink: rgb(229 176 255);
  --light-yellow: rgb(255 255 125);
  --js-yellow: #f7df29;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 18px;
}

body {
  background-color: var(--light-background);
  font-family: Montserrat, sans-serif;
  color: rgb(85 85 85);
}

body.dark {
  background-color: var(--dark-background);
  color: rgb(208 208 211);
}

/* html {
  scroll-snap-type: y mandatory;
}

section {
  scroll-snap-align: start;
} */

a {
  text-decoration: none;
  color: var(--vibrant-purple);
}

header a,
.project-card a {
  display: block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}

header a::after,
.project-card a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: var(--js-yellow);
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: translate3d(-101%, 0, 0);
}

header a:hover::after,
header a:focus::after,
.project-card a:hover::after,
.project-card a:focus::after {
  transform: translate3d(0, 0, 0);
}

a:hover {
  color: var(--vibrant-purple);
}

.bold {
  font-weight: 700;
}

.dark a,
.dark b,
.dark .bold,
.dark h2,
.dark h3 {
  color: white;
}

ul {
  list-style: none;
}

main section {
  max-width: 1144px;
  padding: 0 48px;
  margin: 0 auto;
}

.section-title {
  text-align: center;
  margin-bottom: 48px;
  text-transform: uppercase;
}

.projects-container {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  main section {
    padding: 0 24px;
  }
}
