#about {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
}

#about p,
#about li,
#about h3 {
  font-size: 1.1rem;
}

#about h3 {
  margin: 16px 0 24px;
  line-height: 2.2rem;
}

.about-container {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 95%;
  min-height: 420px;
  box-shadow: 0 1px 3px rgb(145 103 172 / 12%), 0 1px 2px rgb(145 103 172 / 24%);
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.dark .about-container {
  background-color: var(--card-dark);
  box-shadow: 0 1px 3px rgb(37 0 58 / 12%), 0 1px 2px rgb(28 0 45 / 24%);
}

.about-container:hover {
  box-shadow: 0 14px 28px rgb(145 103 172 / 25%), 0 10px 10px rgb(145 103 172 / 22%);
}

.dark .about-container:hover {
  background-color: var(--card-dark-hover);
  box-shadow: 0 14px 28px rgb(54 0 91 / 25%), 0 10px 10px rgb(68 0 107 / 22%);
}

.profile-pic {
  background-image: url("../../../public/images/profile.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.about-text-container {
  padding: 32px;
  box-shadow: -3px 1px 3px rgb(145 103 172 / 12%), 0 1px 2px rgb(145 103 172 / 24%);
  display: flex;
  flex-grow: 1;
  flex-basis: 310px;
  flex-direction: column;
  justify-content: space-between;
}

.about-skills-list {
  display: flex;
  gap: 72px;
}

#about p,
#about li {
  line-height: 2rem;
}

.tools-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tool-badge {
  border-radius: 6px;
}

.pic-container {
  flex-basis: 332px;
}

@media screen and (max-width: 768px) {
  .about-container {
    overflow: visible;
    margin: 84px 24px 0;
    width: auto;
  }

  .profile-pic {
    width: 180px;
    height: 180px;
    position: absolute;
    margin-top: -90px;
    border-radius: 50%;
  }

  .pic-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-basis: auto;
  }

  .about-text-container {
    padding-top: 120px;
    border-radius: 8px 8px 0 0;
    box-shadow: none;
  }
}

@media screen and (max-width: 320px) {
  .profile-pic {
    width: 164px;
    height: 164px;
  }

  .about-text-container {
    padding-top: 98px;
  }

  #about p {
    font-size: 1rem;
  }

  #about h3 {
    font-size: 1rem;
  }
}
