footer {
  background-color: var(--vibrant-purple);
  margin-top: 64px;
  color: white;
  padding: 48px 32px;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dark footer {
  background-color: rgb(36 0 87);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.social-icon {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
  transition: 0.3s;
  border: 2px solid white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.social-icon:hover {
  background-color: white;
}

.dark .social-icon:hover {
  color: rgb(36 0 87);
}
