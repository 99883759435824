#intro {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 72px;
  min-height: 100vh;
  position: relative;
  padding: 70px 48px;
}

.arrow-down {
  position: absolute;
  font-size: 2rem;
  bottom: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.fullwidth {
  box-shadow: 0 8px 20px -20px grey;
  width: 100%;
}

.dark .fullwidth {
  box-shadow: 0 8px 20px -20px rgb(122 0 203);
}

.intro-header {
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--pink);
  margin-bottom: 8px;
}

.intro-name,
.intro-skills {
  font-size: 2.7rem;
  font-weight: 700;
}

.intro-name {
  color: var(--vibrant-purple);
  margin-bottom: 8px;
}

.dark .intro-name {
  color: rgb(251 227 255);
}

.intro-skills {
  color: var(--light-purple);
}

.intro-text {
  margin: 16px 0 48px;
  font-size: 1.2rem;
  line-height: 2.2rem;
}

.trybe-link {
  color: #59c28d;
  transition: color 0.2s;
}

.dark .trybe-link {
  color: #79ffbc;
}

.trybe-link:hover {
  color: #2e6c51;
}

.dark .trybe-link:hover {
  color: #00cf67;
}

.balls {
  position: relative;
}

.purple-ball {
  background-color: var(--light-pink);
  border-radius: 50%;
  min-width: 250px;
  min-height: 250px;
}

.yellow-ball {
  width: 150px;
  height: 150px;
  background-color: var(--light-yellow);
  border-radius: 50%;
  position: absolute;
  top: 0;
  margin-top: -50px;
  right: 0;
  margin-right: -30px;
}

@media screen and (max-width: 768px) {
  /* #intro {
    padding: 70px 48px;
  } */

  .balls {
    display: none;
  }

  .intro-name,
  .intro-skills {
    font-size: 2.2rem;
    font-weight: 700;
  }

  .intro-text {
    font-size: 1.1rem;
  }

  .arrow-down {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  #intro {
    padding: 70px 24px;
  }

  .intro-header {
    font-size: 1rem;
  }

  .intro-name,
  .intro-skills {
    font-size: 1.6rem;
    font-weight: 700;
  }

  .intro-text {
    font-size: 1rem;
  }
}
