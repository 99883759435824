/* stylelint-disable selector-class-pattern */
.btn {
  padding: 16px 32px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  transition: 0.2s;
  cursor: pointer;
}

.btn--outline {
  background-color: unset;
  border: 1px solid var(--vibrant-purple);
  border-radius: 8px;
  color: var(--vibrant-purple);
}

.dark .btn--outline {
  background-color: var(--vibrant-purple);
  color: white;
}

.btn--outline:hover {
  background-color: var(--vibrant-purple);
  color: white;
  box-shadow: 0 14px 28px rgb(145 103 172 / 25%), 0 0 0 rgb(145 103 172 / 22%);
}

/* .dark .btn--outline:hover {
  border: 1px solid var(--vibrant-purple);
} */

@media screen and (max-width: 768px) {
  .btn--outline {
    background-color: var(--vibrant-purple);
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
